<template>
  <div class="order-details-container wrapper">
    <CRow class>
      <CCol lg="9" md="12">
        <CCard>
          <CCardHeader>
            <router-link to="/admin/membership-orders">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{$t("ORDER_DETAILS_COMPONENT_BUTTON_BACK_LINK")}}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody>
            <ValidationObserver ref="form" v-if="!isLoading" v-slot="{ dirty }">
              <CForm>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t("ORDER_DETAILS_COMPONENT_LABEL_ORDER_NUMBER")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <span>{{orderDetails.order_no}}</span>
                    </div>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_DATE_TIME")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <span>{{orderDetails.created_at | moment(dateFormat)}}</span>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_EMAIL")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <span>{{orderDetails.member.email}}</span>
                    </div>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_MEMBERSHIP")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <StakingBadge
                        class="no-border"
                        type="membership-type"
                        :text="orderDetails.membership_type.name"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t("ORDER_DETAILS_COMPONENT_LABEL_PAYMENT_TYPE")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext" v-if="orderDetails.payment_type === 'Bank'">
                      <CIcon name="cil-bank" class="mr-2" />
                      <span class="payment-type">{{orderDetails.payment_type}}</span>
                    </div>

                    <div
                      class="form-control-plaintext"
                      v-if="orderDetails.payment_type === 'Crypto'"
                    >
                      <img
                        v-if="orderDetails.currency_symbol === 'BTC'"
                        src="@/assets/images/currencies/btc.png"
                        alt="BTC"
                        class="currency-icon mr-2"
                      />

                      <img
                        v-if="orderDetails.currency_symbol === 'ETH'"
                        src="@/assets/images/currencies/eth.png"
                        alt="ETH"
                        class="currency-icon mr-2"
                      />

                      <img
                        v-if="orderDetails.currency_symbol === 'USDT'"
                        src="@/assets/images/currencies/erc-20-default.png"
                        alt="USDT"
                        class="currency-icon mr-2"
                      />

                      <span class="payment-type">{{orderDetails.currency_symbol}}</span>
                    </div>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_STATUS")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <StakingBadge
                        v-if="orderDetails.status === 'Verify payment'"
                        type="membership-order-status"
                        class="membership-order-details"
                        :text="orderDetails.status"
                      />
                      <StakingBadge
                        v-else
                        type="membership-order-status"
                        class="membership-order-details"
                        :text="orderDetails.status"
                        :description="orderDetails.status2"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="orderDetails.payment_type === 'Bank'">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_BANK_INFO")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <span>{{orderDetails.bank_name}}</span>
                      <span v-if="orderDetails.branch_name">
                        (
                        <span>{{orderDetails.branch_name}}</span>
                        &nbsp;
                        <span>{{orderDetails.swift}}</span>)
                      </span>
                    </div>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_AMOUNT")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <StakingCurrency
                        :value="orderDetails.amount"
                        :currencySymbol="orderDetails.currency_symbol"
                      />(
                      <StakingCurrency
                        :value="orderDetails.amount_usd"
                        :currencySymbol="orderDetails.membership_type.currency_symbol"
                      />)
                    </div>
                  </CCol>
                </CRow>

                <!-- <CRow class="form-group" v-if="orderDetails.payment_type == 'Bank'">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">Bank Memo</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <span>{{orderDetails.memo}}</span>
                    </div>
                  </CCol>
                </CRow>-->

                <CRow class="form-group" v-if="orderDetails.payment_type === 'Crypto'">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t("ORDER_DETAILS_COMPONENT_LABEL_RECEIVE_ADDRESS")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <StakingExplorerLink
                        type="address"
                        :currencySymbol="orderDetails.currency_symbol"
                        :value="orderDetails.receiving_address.wallet_address"
                      />
                    </div>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_AMOUNT")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <StakingCurrency
                        :value="orderDetails.amount"
                        :currencySymbol="orderDetails.currency_symbol"
                      />(
                      <StakingCurrency
                        :value="orderDetails.amount_usd"
                        :currencySymbol="orderDetails.membership_type.currency_symbol"
                      />)
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="orderDetails.payment_type === 'Crypto'">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t("ORDER_DETAILS_COMPONENT_LABEL_PAYMENT_FORM")}}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext">
                      <span v-if="orderDetails.wallet_id">
                        <span class="web-wallet">
                          <img
                            src="@/assets/images/moonstake-web-wallet.png"
                            alt
                            class="web-wallet-icon"
                          />
                          {{$t("ORDER_DETAILS_COMPONENT_TEXT_MOONSTAKE_WEB_WALLET")}}
                        </span>
                      </span>

                      <span v-else>
                        <span class="external-wallet">
                          <img
                            src="@/assets/images/unknown-web-wallet.png"
                            alt
                            class="web-wallet-icon"
                          />
                          {{$t("ORDER_DETAILS_COMPONENT_TEXT_EXTERNAL_WALLET")}}
                        </span>
                      </span>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="orderDetails.payment_type === 'Crypto'">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t("ORDER_DETAILS_COMPONENT_LABEL_FROM_ADDRESS")}}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext">
                      <StakingExplorerLink
                        type="address"
                        :currencySymbol="orderDetails.currency_symbol"
                        :value="orderDetails.your_wallet_address"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="orderDetails.payment_type === 'Crypto'">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_TXID")}}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext">
                      <StakingExplorerLink
                        type="tx"
                        :currencySymbol="orderDetails.currency_symbol"
                        :value="orderDetails.txid"
                      />
                    </div>
                  </CCol>
                </CRow>
                <ValidationProvider
                  name="ORDER_DETAILS_COMPONENT_LABEL_COMMENT"
                  v-slot="{ errors }"
                  rules="max:1000"
                >
                  <CRow class="form-group" v-if="!isPending">
                    <CCol lg="2" md="2">
                      <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_COMMENT")}}</label>
                    </CCol>
                    <CCol lg="10" md="10">
                      <div class="form-control-plaintext">
                        <span
                          v-if="orderDetails.updated_description_at"
                        >[{{orderDetails.updated_description_at | moment(dateFormat)}}]</span>
                        <div
                          v-html="stringHelper.createMarkupWithNewLine(orderDetails.description)"
                        ></div>
                      </div>
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </CCol>
                  </CRow>

                  <CRow class="form-group mb-5" v-else>
                    <CCol lg="2" md="2">
                      <label class="col-form-label">{{$t("ORDER_DETAILS_COMPONENT_LABEL_COMMENT")}}</label>
                    </CCol>
                    <CCol lg="10" md="10">
                      <div class="float-form">
                        <textarea
                          type="text"
                          class="form-control"
                          v-model="orderDetails.notes"
                          rows="5"
                        />
                      </div>
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </CCol>
                  </CRow>
                </ValidationProvider>
                <ValidationProvider
                  name="ORDER_DETAILS_PAGE_FIELD_NAME_VALIDATE_NOTE"
                  ref="notes"
                  rules="required|max:1000"
                  v-if="isAllowedUpdateDescription"
                >
                  <CRow class="form-group mb-5" slot-scope="{ errors}">
                    <CCol lg="2" md="2">
                      <label class="col-form-label"></label>
                    </CCol>
                    <CCol lg="10" md="10">
                      <div class="float-form">
                        <textarea
                          type="text"
                          class="form-control"
                          v-model="orderDetails.editDescription"
                          rows="5"
                          @focus="showFloatForm()"
                        />
                        <div class="buttons" v-if="isAllowedUpdateDescription && isShownFloatForm">
                          <CButton class="referrer-submit" @click="updateDescription">
                            <CIcon name="cil-check-alt" />
                          </CButton>
                          <CButton @click="cancelUpdateDescription">
                            <CIcon name="cil-x" />
                          </CButton>
                        </div>

                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </div>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton
                      @click="cancel(dirty)"
                      v-if="isAllowedUpdate"
                      class="btn btn-secondary btn-cancel"
                    >{{$t("ORDER_DETAILS_COMPONENT_BUTTON_CANCEL")}}</CButton>
                    <CButton
                      @click="shownRejectModal()"
                      v-if="isAllowedUpdate"
                      class="btn btn-danger btn-reject"
                    >{{$t("ORDER_DETAILS_COMPONENT_BUTTON_REJECT_PAYMENT")}}</CButton>
                    <CButton
                      @click="showApproveConfirmationModal()"
                      v-if="isAllowedUpdate"
                      color="primary"
                      class="btn btn-primary btn-approve"
                    >{{$t("ORDER_DETAILS_COMPONENT_BUTTON_APPROVE_PAYMENT")}}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>

        <CModal
          class="staking-confirmation-modal reject-order-modal"
          :show.sync="isShownRejectModal"
          :centered="true"
        >
          <template #header>
            <CButtonClose class="close-modal" @click="isShownRejectModal = false" />
          </template>
          <div>
            <div class="tit-dele">
              <h4 v-if="stepOnRejectingModal === 1">{{$t("ORDER_DETAILS_COMPONENT_TITLE_DELETE")}}</h4>
              <h4
                v-if="stepOnRejectingModal === 2"
              >{{$t("REJECT_MEMBERSHIP_ORDER_MODAL_STEP_2_TITLE")}}</h4>
            </div>

            <div class="text-modal">
              <ValidationObserver
                v-if="stepOnRejectingModal === 1"
                ref="rejectForm"
                v-slot="{ handleSubmit }"
              >
                <CForm @submit.prevent="handleSubmit(rejectOrder)">
                  <ValidationProvider
                    name="ORDER_DETAILS_PAGE_FIELD_NAME_VALIDATE_OPTION"
                    rules="required"
                  >
                    <CRow class="form-group" slot-scope="{ errors}">
                      <CCol lg="12" md="12">
                        <div
                          role="group"
                          class="form-check"
                          v-for="(option, index) in rejectOptions"
                          :key="index"
                        >
                          <input
                            :id="`uid-${index}`"
                            type="radio"
                            name="reasonOption"
                            class="form-check-input"
                            :value="option.templateName"
                            v-model="reasonOption"
                          />
                          <label :for="`uid-${index}`" class="form-check-label">{{option.subject}}</label>
                          <div>
                            <div
                              class="text-truncate form-text text-muted"
                              v-html="option.description"
                            ></div>
                          </div>
                        </div>

                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                  <ValidationProvider
                    name="EXCHANGE_RATE_PAGE_FIELD_NAME_VALIDATE_COMMENTS"
                    :rules="`${reasonOption == OTHER_OPTION ? 'required': '' }|max:1000`"
                  >
                    <CRow class="form-group" slot-scope="{ errors}">
                      <CCol lg="12" md="12">
                        <textarea
                          type="text"
                          class="form-control"
                          :disabled="reasonOption != OTHER_OPTION"
                          v-model="comments"
                          rows="5"
                        />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                </CForm>
              </ValidationObserver>

              <div v-if="stepOnRejectingModal === 2">
                <div class="reason-option">{{reasonDetails.subject}}</div>
                <div class="reason-description" v-html="reasonDetails.description2"></div>
              </div>
            </div>
          </div>
          <template #footer>
            <CButton
              v-if="stepOnRejectingModal === 1"
              class="btn-cancel"
              @click="isShownRejectModal = false"
              color="dark"
              variant="outline"
            >{{$t("ORDER_DETAILS_COMPONENT_BUTTON_CANCEL")}}</CButton>

            <CButton
              v-if="stepOnRejectingModal === 1"
              @click="showConfirmRejectForm"
              :disabled="!reasonOption"
              class="btn-ok"
            >{{$t("ORDER_DETAILS_COMPONENT_BUTTON_REJECT_PAYMENT")}}</CButton>

            <CButton
              v-if="stepOnRejectingModal === 2"
              class="btn-cancel"
              @click="stepOnRejectingModal = 1"
              color="dark"
              variant="outline"
            >{{$t("REJECT_MEMBERSHIP_ORDER_MODAL_BUTTON_BACK")}}</CButton>

            <CButton
              v-if="stepOnRejectingModal === 2"
              @click="rejectOrder"
              class="btn-ok"
            >{{$t("REJECT_MEMBERSHIP_ORDER_MODAL_BUTTON_CONFIRM_REJECTION")}}</CButton>
          </template>
        </CModal>

        <CModal
          class="staking-confirmation-modal confirm-approving-order-modal"
          :show.sync="isShownApproveConfirmationModal"
          :centered="true"
        >
          <template #header>
            <CButtonClose class="close-modal" @click="isShownApproveConfirmationModal = false" />
          </template>
          <div>
            <div class="pb-4 tit-dele">
              <h4>{{$t("ORDER_DETAILS_COMPONENT_APPROVE_CONFIRMATION_MODAL_TITLE")}}</h4>
            </div>
            <div class="text-modal">{{$t("ORDER_DETAILS_COMPONENT_APPROVE_CONFIRMATION_BODY_TEXT")}}</div>
          </div>
          <template #footer>
            <CButton
              class="btn-cancel"
              @click="isShownApproveConfirmationModal = false"
              color="dark"
              variant="outline"
            >{{$t("ORDER_DETAILS_COMPONENT_APPROVE_CONFIRMATION_MODAL_BUTTON_CANCEL")}}</CButton>

            <CButton
              @click="approveOrder"
              class="btn-ok"
            >{{$t("ORDER_DETAILS_COMPONENT_APPROVE_CONFIRMATION_MODAL_BUTTON_OK")}}</CButton>
          </template>
        </CModal>

        <CModal class="leave-page-modal" :show.sync="warningLeavePage" :centered="true">
          <div class>
            <div class="tit-leave">
              <h4>{{$t("ORDER_DETAILS_COMPONENT_LABEL_MODAL_TITLE_LEAVE")}}</h4>
            </div>
            <p class="text-modal">{{$t("ORDER_DETAILS_COMPONENT_MODAL_CONTENT_UNSAVED_CHANGES")}}</p>
            <p class="text-modal">{{$t("ORDER_DETAILS_COMPONENT_MODAL_QUESTION_UNSAVED_CHANGES")}}</p>
          </div>

          <template #footer>
            <CButton
              class="btn btn-stay"
              @click="warningLeavePage = false"
              color="dark"
              variant="outline"
            >{{$t("ORDER_DETAILS_COMPONENT_BUTTON_STAY_ON_THIS_PAGE")}}</CButton>

            <router-link
              class="btn btn-leave"
              to="/admin/membership-orders"
            >{{$t("ORDER_DETAILS_COMPONENT_TEXT_LEAVE_THIS_PAGE")}}</router-link>
          </template>
        </CModal>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import currencyHelper from '@/utils/currency-helper';
import { MembershipOrderStatus } from '@/enums';
import StakingBadge from '@/components/StakingBadge';
import StakingExplorerLink from '@/components/StakingExplorerLink';
import StakingCurrency from '@/components/StakingCurrency';
import { Constants, env } from '@/constants';

const OTHER_OPTION = '___OTHER___';

export default {
  name: 'OderDetails',
  components: {
    VclList,
    StakingBadge,
    StakingExplorerLink,
    StakingCurrency,
  },
  data() {
    return {
      childPoolName: env.theme[env.theme.name].name,
      orderDetails: null,
      isLoading: true,
      reasonOption: null,
      rejectOptions: [],
      comments: null,
      dateFormat: Constants.dateFormat,
      isShownRejectModal: false,
      isShownApproveConfirmationModal: false,
      isAllowedUpdate: false,
      isAllowedUpdateDescription: false,
      isFormChanged: false,
      warningLeavePage: false,
      isShownFloatForm: false,
      OTHER_OPTION: OTHER_OPTION,
      stepOnRejectingModal: 1,
      stringHelper,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    reasonDetails() {
      const option = this.rejectOptions.find(x => x.templateName === this.reasonOption);
      const result = option || {};

      if (this.reasonOption === OTHER_OPTION) {
        result.description2 = stringHelper.createMarkupWithNewLine(this.comments);
      } else {
        result.description2 = result.description;
      }

      return result;
    },
  },
  async mounted() {
    const orderId = this.$route.params.orderId;
    this.checkPermissions();

    this.getMembershipOrderDetails(orderId);
    this.getRejectOptions();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_UPDATE_ORDER') {
            this.isAllowedUpdate = true;
          }
        });
      }
    },
    async getMembershipOrderDetails(orderId) {
      try {
        const res = await this.$http.get(endpoints.getMembershipOrderDetails(orderId));
        this.orderDetails = res.data;
        this.orderDetails.status = MembershipOrderStatus[this.orderDetails.status];
        this.orderDetails.currency_symbol = currencyHelper.getMappingCurrencySymbol(this.orderDetails.currency_symbol);
        this.orderDetails.status2 = `[${moment(this.orderDetails.approved_at).format(Constants.dateFormat)}]`;
        this.isAllowedUpdate = this.isAllowedUpdate && this.orderDetails.status === MembershipOrderStatus.Pending;
        this.isAllowedUpdateDescription = this.orderDetails.status !== MembershipOrderStatus.Pending;
        this.isPending = this.orderDetails.status === MembershipOrderStatus.Pending;
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_DETAILS_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getRejectOptions() {
      try {
        const res = await this.$http.get(endpoints.getRejectOptions('CHILDPOOL_MEMBERSHIP_ORDER_REJECTED'));
        const items = _.sortBy(res.data, 'display_order');
        const rejectOptions = items
          .filter(item => item.language === 'en')
          .map(item => {
            return {
              templateName: item.name,
              subject: item.option_name,
              description: item.subject,
            };
          });

        rejectOptions.push({
          templateName: OTHER_OPTION,
          subject: this.$t('REJECT_MEMBERSHIP_ORDER_MODAL_OTHER_REASON'),
          description: null,
        });

        this.rejectOptions = rejectOptions;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_DETAILS_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    backToMembershipOrders() {
      this.$router.push('/admin/membership-orders');
    },
    async showApproveConfirmationModal() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      this.isShownApproveConfirmationModal = true;
    },
    async approveOrder() {
      if (this.orderDetails.isProcessing) {
        return;
      }

      this.orderDetails.isProcessing = true;

      try {
        const data = {
          note: this.orderDetails.notes || '',
        };
        const result = await this.$http.put(endpoints.approveMembershipOrder(this.orderDetails.id), data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: `${this.$t('ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_FIRST_MESSAGE')} ${this.orderDetails.member.email} ${this.$t(
            'ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_APPROVED_MESSAGE',
          )}`,
        });
        this.$router.push('/admin/membership-orders');
      } catch (err) {
        this.orderDetails.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_DETAILS_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async shownRejectModal() {
      this.isShownRejectModal = true;
      this.stepOnRejectingModal = 1;
    },
    async showConfirmRejectForm() {
      const isValid = await this.$refs.rejectForm.validate();
      if (!isValid) {
        return;
      }

      this.stepOnRejectingModal = 2;
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push('/admin/membership-orders');
      }
    },
    showFloatForm() {
      this.isShownFloatForm = true;
    },
    hideFloatForm() {
      this.isShownFloatForm = false;
    },
    cancelUpdateDescription() {
      this.orderDetails.editDescription = '';

      this.isShownFloatForm = false;
    },
    async updateDescription() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (this.orderDetails.isProcessing) {
        return;
      }

      this.orderDetails.isProcessing = true;
      try {
        const data = {
          description: this.orderDetails.editDescription,
        };
        const result = await this.$http.put(endpoints.updateDescriptionForMembershipOrder(this.orderDetails.id), data);

        this.orderDetails.isProcessing = false;
        this.isShownFloatForm = false;

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: `${this.$t('ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_FIRST_MESSAGE')} ${this.orderDetails.member.email} ${this.$t(
            'ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_UPDATED_MESSAGE',
          )}`,
        });
        this.$router.push('/admin/membership-orders');
      } catch (err) {
        this.orderDetails.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_DETAILS_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async rejectOrder() {
      if (this.orderDetails.isProcessing) {
        return;
      }

      this.orderDetails.isProcessing = true;
      try {
        const data = {};
        if (this.reasonOption === OTHER_OPTION) {
          data.note = this.comments;
        } else {
          data.template = this.reasonOption;
        }

        const result = await this.$http.put(endpoints.rejectMembershipOrder(this.orderDetails.id), data);

        this.orderDetails.isProcessing = false;
        this.isShownRejectModal = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: `${this.$t('ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_FIRST_MESSAGE')} ${this.orderDetails.member.email} ${this.$t(
            'ORDER_DETAILS_PAGE_NOTIFY_SUCCESS_REJECTED_MESSAGE',
          )}`,
        });
        this.$router.push('/admin/membership-orders');
      } catch (err) {
        this.orderDetails.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_DETAILS_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.order-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }

    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      .staking-currency-small {
        line-height: 22px;
      }
    }

    .payment-type {
      display: inline-block;
    }

    .membership-order-details.staking-badge.membership-order-status.verify-payment {
      color: #5da30b;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      border: 1px solid #dadde1;
      background-color: #ffffff;
    }

    .web-wallet,
    .external-wallet {
      height: 18px;
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }

    .currency-icon {
      width: 20px;
      height: 20px;
    }

    .explorer-link {
      display: inline-block;
    }

    .form-group {
      margin-bottom: 0.5rem;
      label {
        width: 100%;
        text-align: right;
      }
    }

    .float-form {
      position: relative;
      .buttons {
        position: absolute;
        right: 0;
        bottom: -36px;
        z-index: 99;
        button {
          width: 35px;
          height: 35px;
          background: #f5f6f8;
          color: #3a4a7f;
          box-shadow: -2px 2px 5px -2px #00000080;
          padding: 0;
          &.referrer-submit {
            margin-right: 10px;
          }
        }
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 36px;

        &.btn-approve {
          background-color: #5da30b;
        }

        &.btn-reject {
          color: #ffffff;
          font-weight: 500;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
}

.staking-confirmation-modal.reject-order-modal {
  h4 {
    height: 21px;
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }
  .comments-label {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.staking-confirmation-modal.confirm-approving-order-modal {
  .modal-content {
    .form-text.text-muted {
      color: rgba(138, 147, 162, 0.5);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }

    .reason-option {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }
    .reason-description {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }

    .modal-footer {
      .btn-ok {
        background-color: #5da30b;
      }
    }
  }
}
</style>
